import React, { useState } from 'react';
import { usePathname } from 'next/navigation';
import {
  AiOutlineHome,
  AiOutlineShop,
  AiOutlineThunderbolt,
  AiOutlineStar,
  AiOutlineFile,
  AiOutlineTeam,
  AiOutlineUser,
} from 'react-icons/ai';
import { BiSupport } from 'react-icons/bi';

export function HeaderNavMenu() {
  const [loading, setLoading] = useState<number | null>(null);
  const pathname = usePathname();

  const navItems = [
    {
      url: '/',
      icon: <AiOutlineHome className="w-6 lg:hidden" />,
      name: 'Home',
    },
    {
      url: '/menu',
      icon: <AiOutlineShop className="w-6 lg:hidden" />,
      name: 'Menu',
    },
    {
      url: '/offers',
      icon: <AiOutlineThunderbolt className="w-6 lg:hidden" />,
      name: 'Offers',
    },
    {
      url: '/fan-club',
      icon: <AiOutlineStar className="w-6 lg:hidden" />,
      name: 'Fan Club',
    },
    {
      url: '/blog',
      icon: <AiOutlineFile className="w-6 lg:hidden" />,
      name: 'Blog',
    },
    {
      url: '/about-us',
      icon: <AiOutlineTeam className="w-6 lg:hidden" />,
      name: 'About Us',
    },
    {
      url: '/account',
      icon: <AiOutlineUser className="w-6 lg:hidden" />,
      name: 'My Account',
    },
    {
      url: '/support',
      icon: <BiSupport className="w-6 lg:hidden" />,
      name: 'Support',
    },
  ];

  return (
    <nav className="grid gap-5 py-5 grid-cols-2 lg:flex lg:items-center font-medium">
      {navItems.map((item, index) => (
        <a
          href={item.url}
          className={`flex gap-2 justify-between items-center bg-crunchies-white shadow-lg lg:shadow-none p-3 lg:p-2 lg:hover:bg-gray-100 rounded-lg transition-colors duration-200`}
          onClick={() => setLoading(index)}
          key={index}
        >
          <div
            className={`flex gap-2 items-center text-sm lg:text-base ${
              item.url === '/valbox'
                ? 'hidden lg:flex w-[11.313rem] h-[2.5rem] rounded-[2.5rem] border-[0.094rem] px-2 py-6 justify-center'
                : ''
            } ${
              item.url === pathname
                ? 'text-crunchies-red'
                : 'text-gray-700 hover:text-crunchies-red'
            }`}
          >
            {item.icon}
            <div>{item.name}</div>
          </div>
          {loading === index && (
            <div className="flex gap-1">
              <div className="w-1 h-1 bg-crunchies-red rounded-full animate-bounce"></div>
              <div className="w-1 h-1 bg-crunchies-red rounded-full animate-bounce delay-75"></div>
              <div className="w-1 h-1 bg-crunchies-red rounded-full animate-bounce delay-150"></div>
            </div>
          )}
        </a>
      ))}
    </nav>
  );
}
